import * as Yup from 'yup';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
// components
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast, { Toaster } from 'react-hot-toast';
import { sentenceCase } from 'change-case';
import { DeleteRequest } from '../api/DeleteRequest';
import { PutRequest } from '../api/PutRequest';
import { PostRequest } from '../api/PostRequest';
import { FormProvider, RHFTextField } from '../components/hook-form';
import { GetRequest } from '../api/GetRequest';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Label from '../components/Label';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Users() {
  const [page, setPage] = useState(0);
  
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState(null);

  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  
  const [order, setOrder] = useState('asc');

  const [Data, setData] = useState([]);

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');


  const Schema = Yup.object().shape({

  });

  const defaultValues = {
    name: '',
    opposite: '',
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleRowsChange = (e) => {
    setPage(0)
    setRowsPerPage(e.target.value)
  }
  const handleChangePage = (event, newPage) => {
    // newPage = page Index (starts from 0)
    // request new page data 
    window.scrollTo(0,0);
    setPage(newPage);
  };



  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const fetchData = async () => {
    try {        
        const res = await GetRequest('get_users', [
          ['pageSize', rowsPerPage],
          ['pageNumber', page+1]
        ]);
        setData(res.data.data);
        setTotal(res.data.count)
    } catch (error) {
        console.log(error);
    }
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total) : 0;

  const filteredUsers = applySortFilter(Data, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  
  const notify = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);
  const handleClose = () => {
      setValue('name', '')
      setValue('opposite', '')
      setOpen(false);
      setId(null);
  }
  const onSubmit = async (data) => {
    
    setOpen(false);
     const res = await PutRequest('ch_user_status',id, {active: !status});
     if(res?.data?.success){
        fetchData();
        notify(res.data.message)
        setId(null);
        reset();
      }else{
        notifyErr(res.message || 'Something Went Wrong !')
      }
      
    }

  useEffect(() => {
  
    fetchData();
  
  }, [page,rowsPerPage])

  return (
    <Page title="Users">
      <Container>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: '',
            duration: 4000,
            style: {background: '#363636',color: '#fff'},
            // Default options for specific types
            success: {
              duration: 4000,
              theme: {primary: 'green',secondary: 'black'},
            },
          }}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* <Button variant="contained" id='add-char' sx={{background: '#006adf'}} component={RouterLink} onClick={()=> setOpen(true)} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Chrecter
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={Data.length}
                  // numSelected={selected.length}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {Data.map((row) => {
                    const { _id, name , phone, email , active , verified} = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}

                      >
                        <TableCell component="th" scope="row">
                              {name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                              {email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                              {phone}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{color:verified ? "green" :"red" }} >
                              {verified ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Label variant="ghost" color={(!active && 'error') || 'success'}>
                              {sentenceCase(active ? "active" : "banned")}
                            </Label>
                        </TableCell>

                        <TableCell align="right">
                          <UserMoreMenu 
                            customFunc={()=> {setId(_id); setOpen(true); setStatus(active)}}
                            customIco={!active ? 'checkmark-circle-outline' : 'close-circle-outline'}
                            customLbl={active ? 'Ban' :"Activate"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleRowsChange}
            onPageChange={handleChangePage}
          />
        </Card>
        {/* MODALS */}
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle >{status? 'Block':'Active'} User</DialogTitle>
            <DialogContent >
                <Typography variant='h6' >Are you sure want to {status ? 'Block' : 'Activate'} this user</Typography>
            </DialogContent>
            <DialogActions>
                <Button  onClick={handleClose} color='error' >Cancel</Button>
                <Button type='submit' id='modal-add-btn' sx={{background: '#006adf', color: 'white'}} >Confirm</Button>
            </DialogActions>
            </FormProvider>
        </Dialog>
      </Container>
    </Page>
  );
}
