import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Reports';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Charecters from './pages/Charecters';
import Users from './pages/Users';
import CompanyInfo from './pages/CompanyInfo';

// ----------------------------------------------------------------------

export default function Router() {

  const token = sessionStorage.getItem('token');
  
  return useRoutes([
    {
      path: '/dashboard',
      // element:  <DashboardLayout /> ,
      element: token ?  <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: <Users /> },
        { path: 'characters', element: <Charecters /> },
        { path: 'reports', element: <Blog /> },
        { path: 'co-info', element: <CompanyInfo /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: token ? <LogoOnlyLayout /> : <Navigate to="/login" replace />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
