import { Backdrop, Button, CircularProgress, OutlinedInput, Typography } from '@mui/material';
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftjsToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast, Toaster } from 'react-hot-toast';
import { PostRequest } from '../api/PostRequest';
import { GetRequest } from '../api/GetRequest';

const CompanyInfo = () => {
    const [contact, setContact] = useState({
        email: "",
        phone: ""
    })
    const [values, setValues] = useState({
        policy: "",
        terms: ""
    })
    const [open, setOpen] = useState(false)
    const notify = (msg) => toast.success(msg);
    const notifyErr = (msg) => toast.error(msg);
    const [editorState1, setEditorState1] = useState(EditorState.createEmpty())
    const onEditorState1Change = (target) => {
        setEditorState1(target);
        setValues((v) => ({...v, terms: draftjsToHtml(convertToRaw(target.getCurrentContent())) }))
    }
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const onEditorStateChange = (target) => {
        setEditorState(target)   
        setValues((v) => ({...v, policy: draftjsToHtml(convertToRaw(target.getCurrentContent())) }))
    }

 const handleSave = async () => {
     setOpen(true)
     try {
         await PostRequest('edit_contact', contact)
         await PostRequest('policy', {policy: values.policy})
         const res1 = await PostRequest('terms', {term: values.terms});
         if(res1.data.success){
            setOpen(false)
        }
        notify(res1.data.message);
    } catch (error) {
        setOpen(false)
        notifyErr('Failed To Save Content !');
    }
 }
 const fetchData = async () => {
     setOpen(true)
    try {
        const res2 = await GetRequest('get_contact');
        if(res2.data.success){
            setContact(res2.data.info);
        }
        const res = await GetRequest('policy');
        const res1 = await GetRequest('terms');
        if(res1.data.success){
            setOpen(false)
        }
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
        convertFromHTML(res.data.policy.policy)
        )))
        setEditorState1(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(res1.data.term.term)
                )))
            } catch (error) {
                setOpen(false)
        notifyErr('Failed To Get Content !');
    }
 }

 useEffect(() => {
   fetchData();
 }, [])
 
  return (
    <div style={{margin: "0 40px"}} >
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: '',
            duration: 4000,
            style: {background: '#363636',color: '#fff'},
            // Default options for specific types
            success: {
              duration: 4000,
              theme: {primary: 'green',secondary: 'black'},
            },
          }}
        />
        <div style={{display: 'flex', justifyContent: "space-between"}} >
            <Typography variant="h3" >Contact Info</Typography>
            <Button type='submit' onClick={handleSave} id='modal-add-btn' sx={{background: '#006adf', color: 'white', padding: "0 40px"}} >Save</Button>
        </div>
        <br/>
        <div  style={{display: "flex"}}>
            <div >
                <span style={{marginLeft: "5px"}} >Email</span><br/>
                <OutlinedInput  sx={{borderRadius: "20px", width: "300px"}} value={contact.email} onChange={({target}) => setContact((v) => ({...v, email: target.value}))} placeholder="Contact Email" type="email" name="email" />
            </div>
            <span style={{margin: "0 30px"}} />
            <div>
                <span style={{marginLeft: "5px"}} >Phone</span><br/>
                <OutlinedInput sx={{borderRadius: "20px", width: "300px"}} value={contact.phone} onChange={({target}) => setContact((v) => ({...v, phone: target.value}))} placeholder="Contact Phone" name="phone" />
            </div>
        </div>
        <br/>
        <Typography variant="h3" >Privacy & Policy</Typography>
        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            />
        <Typography variant="h3" >Terms and Conditions</Typography>
        <br/>
        <Editor
            editorState={editorState1}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorState1Change}
        />
    </div>
  )
}

export default CompanyInfo